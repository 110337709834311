import { Decimal } from 'decimal.js-light'
import { CollapsibleList, ListItem, ListItemGraphic, ListItemText, ListItemPrimaryText, ListItemSecondaryText, ListItemMeta, ListDivider } from '@rmwc/list'
import { Wheel } from './wheels/FruitMania'
import { currencyFormat } from '../../utils/Number'

export function FruitMania ({ transaction, darkMode }) {
  const date = new Date(parseFloat(transaction.date) * 1000)
  const dateString = date.toLocaleString([], { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' })
  const winlose = new Decimal(transaction.winAmount).sub(new Decimal(transaction.bet))
  let icon = winlose.greaterThan(0) ? 'attach_money' : 'money_off'
  let color = winlose.greaterThan(0) ? 'green' : 'red'
  if (transaction.payout !== 'Successful') {
    icon = 'running_with_errors'
    color = 'red'
  }
  const darkModeStyle = darkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.15)'
  return (
    <CollapsibleList handle={
      <div>
        <ListItem>
          <ListItemGraphic icon={icon} style={{ color: color }} />
          <ListItemText>
            <ListItemPrimaryText style={{ fontWeight: '500' }}>{transaction.currency} {currencyFormat(winlose.toNumber())}</ListItemPrimaryText>
            <ListItemSecondaryText>
              bet {currencyFormat(transaction.bet)} win {currencyFormat(transaction.winAmount)} at {dateString}
            </ListItemSecondaryText>
          </ListItemText>
          <ListItemMeta icon='chevron_right' />
        </ListItem>
        <ListDivider />
      </div>
    }
    >
      <div style={{
        padding: '1rem',
        textAlign: 'center',
        backgroundColor: darkModeStyle
      }}
      >
        <div style={{
          textAlign: 'left',
          marginBottom: '1rem'
        }}
        >
          <div>Date: <b>{date.toLocaleString()}</b></div>
          <div>End Balance: <b>{currencyFormat(transaction.balance)}</b></div>
          <div>Payout: <b>{transaction.payout}</b></div>
        </div>
        <Wheel transaction={transaction} />
        <div><i><small>Ref:{transaction.id}</small></i></div>
      </div>

    </CollapsibleList>
  )
}
