import { Logo } from './Logo'

export function Footer ({ darkMode }) {
  return (
    <footer style={{
      marginTop: '1rem'
    }}
    >
      <Logo style={{ width: '5rem' }} />
    </footer>
  )
}
