export function getDisappearCells (wins) {
  const cells = []
  for (let i = 0; i < wins.length; i++) {
    const w = wins[i]
    if (!w) {
      continue
    }
    let wc = []
    if (w.kind === 'mul') {
      wc = w.line.cells
    } else if (w.kind === 'scat' || w.kind === 'bt') {
      wc = w.cells
    }
    for (let j = 0; j < wc.length; j++) {
      const c = wc[j]
      const r = cells[c.reel] || []
      r.push(c)
      cells[c.reel] = r
    }
  }
  return cells
}

export function cellsAsMatrix (cells, reel, row) {
  const matrix = Array(reel).fill().map(() => Array(row).fill(0))
  cells.forEach(c => {
    matrix[c.reel][c.row] = 1
  })
  return matrix
}

export function getDisappearCellsAsMatrix (wins, reel, row) {
  const cells = getDisappearCells(wins).flat()
  return cellsAsMatrix(cells, reel, row)
}
