import useSWR from 'swr'
import axios from 'axios'
import {
  useLocation
} from 'react-router-dom'
import { useEffect } from 'react'

const key = 'WOOLILI_USER'

async function auth (endpoint, merchant, token, userId, currency, game) {
  if (merchant && token && userId) {
    const url = `${process.env.REACT_APP_ENDPOINT_API}${endpoint}?m=${merchant}&token=${token}&user=${userId}&currency=${currency}&gameId=${game}`
    const rsp = await axios.get(url)
    return rsp.data
  } else {
    const x = window.sessionStorage.getItem(key)
    if (x) {
      return JSON.parse(x)
    } else {
      throw new Error('Invalid parameters')
    }
  }
}

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

export function useUser () {
  const query = useQuery()
  const merchant = query.get('m')
  const token = query.get('token')
  const userId = query.get('u')
  const currency = query.get('c') || 'KSH'
  const game = query.get('g')

  const { data, error } = useSWR(['users/auth', merchant, token, userId, currency, game], auth)

  useEffect(() => {
    if (data) {
      window.sessionStorage.setItem(key, JSON.stringify(data))
    }
    if (error) {
      window.sessionStorage.removeItem(key)
    }
  }, [merchant, token, userId, error, data])

  return {
    user: data,
    game,
    isLoading: !error && !data,
    isError: error
  }
}
