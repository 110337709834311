import { useUser } from '../data/User'
import { AppLayout } from '../components/AppLayout'
import { UserProfile } from '../components/UserProfile'
import Loading from '../components/Loading'
import LoginFailed from '../components/LoginFailed'

const PageContent = () => {
  const { user, isLoading, isError } = useUser()

  if (isError) return <LoginFailed />
  if (isLoading) return <Loading text='Verifying user token..' />

  return <UserProfile user={user} />
}

export default function Home () {
  return (
    <AppLayout title='Home'>
      <PageContent />
    </AppLayout>
  )
}
