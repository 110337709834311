import { CircularProgress } from '@rmwc/circular-progress'
import useDarkMode from 'use-dark-mode'

export default function Loading ({ text }) {
  const darkMode = useDarkMode()
  const darkStyle = darkMode.value ? { color: 'white' } : undefined
  return (
    <div style={{ width: '100%', margin: 'auto', padding: '2rem', textAlign: 'center' }}>
      <CircularProgress style={darkStyle} size='large' />
      <div style={{ padding: '1rem' }}>{text}</div>
    </div>
  )
}

export function SimpleLoading ({ size }) {
  const darkMode = useDarkMode()
  const darkStyle = darkMode.value ? { color: 'white' } : undefined
  return <CircularProgress style={darkStyle} size={size} />
}
