import { FruitMania } from './FruitMania'
import { CandyBurst } from './CandyBurst'

export function GameTransation ({ game, transaction, darkMode }) {
  switch (transaction.game) {
    case 'candy-burst':
    case 'fanatic-ape':
      return <CandyBurst transaction={transaction} darkMode={darkMode} />
    default:
      return <FruitMania transaction={transaction} darkMode={darkMode} />
  }
}
