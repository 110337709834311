import {
  Drawer,
  DrawerHeader,
  DrawerTitle,
  DrawerContent
} from '@rmwc/drawer'
import { ThemeProvider } from '@rmwc/theme'
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarFixedAdjust,
  TopAppBarActionItem
} from '@rmwc/top-app-bar'
import { useState } from 'react'
import useDarkMode from 'use-dark-mode'
import { darkTheme, lightTheme } from './Theme'
import { Nav } from './Nav'
import { Logo } from './Logo'
import { Footer } from './Footer'

export function AppLayout ({ title, children }) {
  const darkMode = useDarkMode(false)
  const [open, setOpen] = useState(false)
  const theme = darkMode.value ? darkTheme : lightTheme
  return (
    <ThemeProvider options={theme}>
      <TopAppBar fixed>
        <TopAppBarRow>
          <TopAppBarSection>
            <TopAppBarTitle>{title}</TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <TopAppBarActionItem icon='invert_colors' onClick={() => darkMode.toggle()} />
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <Drawer
        modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <DrawerHeader>
          <DrawerTitle><Logo style={{ width: '100%', margin: '2rem 0' }} /></DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <Nav darkMode={darkMode.value} onClick={() => setOpen(false)} />
        </DrawerContent>
      </Drawer>
      <TopAppBarFixedAdjust />
      {children}
      <Footer darkMode={darkMode.value} />
    </ThemeProvider>
  )
}
