import { List } from '@rmwc/list'
import { Card, CardActions, CardActionButton } from '@rmwc/card'
import { useUser } from '../../data/User'
import { GameTransation } from '../../components/GameTransaction'
import Loading, { SimpleLoading } from '../../components/Loading'
import LoginFailed from '../../components/LoginFailed'
import { UserBalance } from '../../components/UserBalance'
import useDarkMode from 'use-dark-mode'
import useSWRInfinite from 'swr/infinite'
import * as Client from '../../data/Client'

// function getPageTitle (id) {
//   if (id === 'fruit-mania') {
//     return 'Fruit Mania'
//   } else if (id === 'euro-cup') {
//     return 'Euro Cup'
//   } else if (id === 'candy-burst') {
//     return 'Candy Burst'
//   } else if (id === 'fanatic-ape') {
//     return 'Fanatic Ape'
//   } else {
//     return 'Game Transactions'
//   }
// }

const fetcher = async (token, game, cursor) => {
  if (token) {
    const body = {
      game: game,
      cursor: cursor === 'start' ? null : cursor,
      size: 10
    }
    const client = Client.create(token)
    const rsp = await client.post('users/slots/transactions', body)
    return rsp.data
  } else {
    return { items: [], noMoreData: true }
  }
}

const GameTransations = () => {
  const darkMode = useDarkMode()
  const userLoader = useUser()
  const gameId = userLoader.game
  const token = userLoader.user?.token
  const dataLoader = useSWRInfinite((i, prev) => {
    if (prev && prev.noMoreData) {
      return null
    }
    if (i === 0) {
      return `token=${encodeURIComponent(token)}&game=${gameId}`
    }
    return `token=${encodeURIComponent(token)}&game=${gameId}&cursor=${encodeURIComponent(prev.cursor)}`
  }, query => {
    const u = new URLSearchParams(query)
    return fetcher(token, gameId, u.get('cursor'))
  })

  if (userLoader.isError) return <LoginFailed />

  if (userLoader.isLoading) {
    return (
      <Loading text='Verifying user token..' />
    )
  }

  const isLoadingInitialData = !dataLoader.error && !dataLoader.data
  const isLoadingMore =
    isLoadingInitialData ||
    (dataLoader.size > 0 && typeof dataLoader.data[dataLoader.size - 1] === 'undefined')
  const isRefreshing = dataLoader.isValidating && dataLoader.data && dataLoader.data.length === dataLoader.size
  const isError = dataLoader.error
  const transactions = dataLoader.data ? dataLoader.data.flatMap(x => x.items) : []
  const lastPage = dataLoader.data ? dataLoader.data[dataLoader.data.length - 1] : {}

  const loadMoreIcon = isLoadingMore ? <SimpleLoading /> : 'expand_more'
  const noMore = !isLoadingMore && !isError && lastPage.noMoreData
  const loadMoreLabel = noMore ? 'No more data' : isLoadingInitialData ? 'Loading' : 'Load More Data'
  const loadMoreStyle = darkMode.value ? { color: 'white' } : undefined

  return (
    <>
      <UserBalance user={userLoader.user} />
      <Card style={{ margin: '0.5rem' }}>
        {isLoadingMore && <SimpleLoading text='Loading data..' />}
        {isRefreshing && <SimpleLoading text='Refreshing data..' />}
        <List twoLine>
          {transactions.map((x, i) => <GameTransation key={i} game={userLoader.game} transaction={x} darkMode={darkMode.value} />)}
        </List>
        {isError && <div style={{ margin: '0.5rem' }}>failed to load data</div>}
        <CardActions fullBleed>
          <CardActionButton
            style={loadMoreStyle}
            label={loadMoreLabel}
            trailingIcon={loadMoreIcon}
            disabled={isLoadingMore || noMore}
            onClick={() => dataLoader.setSize(dataLoader.size + 1)}
          />
        </CardActions>
      </Card>
    </>
  )
}

export default GameTransations
