import { useLocation, useHistory } from 'react-router-dom'
import { List, ListItem, ListItemGraphic, ListItemText } from '@rmwc/list'

const MenuItem = ({ href, icon, darkMode, onClick, children }) => {
  const location = useLocation()
  const history = useHistory()

  const handleClick = e => {
    e.preventDefault()
    history.push(href)
    onClick()
  }

  const style = darkMode ? { color: 'rgb(241, 241, 241)' } : undefined

  return (
    <ListItem
      onClick={handleClick}
      activated={location.pathname === href}
      style={style}
    >
      <ListItemGraphic style={style} icon={icon} />
      <ListItemText>{children}</ListItemText>
    </ListItem>
  )
}

export function Nav ({ darkMode, onClick }) {
  return (
    <List>
      <MenuItem darkMode={darkMode} icon='home' href='/' onClick={onClick}>Home</MenuItem>
      <MenuItem darkMode={darkMode} icon='history' href='/user/slots/transactions' onClick={onClick}>Transactions</MenuItem>
    </List>
  )
}
