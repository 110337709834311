import { Decimal } from 'decimal.js-light'
import { CollapsibleList, ListItem, ListItemGraphic, ListItemText, ListItemPrimaryText, ListItemSecondaryText, ListItemMeta, ListDivider } from '@rmwc/list'
import { Wheel } from './wheels/CandyBurst'
import { currencyFormat } from '../../utils/Number'

function getDate (transaction) {
  return new Date(parseFloat(transaction.date) * 1000)
}

function ExpendedContainer ({ children, darkMode, transaction }) {
  const darkModeStyle = darkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.15)'
  return (
    <div style={{
      padding: '1rem',
      textAlign: 'center',
      backgroundColor: darkModeStyle
    }}
    >
      {children}
      <Wheel transaction={transaction} />
      <div><i><small>Ref:{transaction.id}</small></i></div>
    </div>
  )
}

function DetailContainer ({ children }) {
  return (
    <div style={{
      textAlign: 'left',
      marginBottom: '1rem'
    }}
    >
      {children}
    </div>
  )
}

function GotFreeSpin ({ transaction, darkMode }) {
  const date = getDate(transaction)
  return (
    <CollapsibleList
      handle={
        <div>
          <ListItem>
            <ListItemGraphic icon='emoji_events' style={{ color: 'green' }} />
            <ListItemText>
              <ListItemPrimaryText style={{ fontWeight: '500' }}>WON FREE SPIN</ListItemPrimaryText>
              <ListItemSecondaryText>
                {transaction.state.count} spin
              </ListItemSecondaryText>
            </ListItemText>
            <ListItemMeta icon='chevron_right' />
          </ListItem>
          <ListDivider />
        </div>
        }
    >
      <ExpendedContainer darkMode={darkMode} transaction={transaction}>
        <DetailContainer>
          <div>Date: <b>{date.toLocaleString()}</b></div>
        </DetailContainer>
      </ExpendedContainer>
    </CollapsibleList>
  )
}

function MainGameRestored ({ transaction, darkMode }) {
  const date = getDate(transaction)
  return (
    <CollapsibleList
      handle={
        <div>
          <ListItem>
            <ListItemGraphic icon='restore' /> MAIN GAME RESTORED
          </ListItem>
          <ListDivider />
        </div>
      }
    >
      <ExpendedContainer darkMode={darkMode} transaction={transaction}>
        <DetailContainer>
          <div>Date: <b>{date.toLocaleString()}</b></div>
          <div>End Balance: <b>{currencyFormat(transaction.balance)}</b></div>
        </DetailContainer>
      </ExpendedContainer>

    </CollapsibleList>
  )
}

function stateName ({ cascading }) {
  let round = 'COLLAPSED'
  if (cascading.restore) {
    round = 'MAIN GAME RESTORED'
  } else if (cascading.beginning && cascading.end) {
    round = 'COMPLETED'
  } else if (cascading.beginning) {
    round = 'CASCADING STARTED'
  } else if (cascading.end) {
    round = 'CASCADING STOPPED'
  }
  return round
}

function StandardItem ({ transaction, darkMode }) {
  const date = getDate(transaction)
  const winlose = new Decimal(transaction.winAmount).sub(new Decimal(transaction.bet))
  let icon = winlose.greaterThanOrEqualTo(0) ? 'attach_money' : 'money_off'
  let color = winlose.greaterThanOrEqualTo(0) ? 'green' : 'red'
  if (transaction.payout !== 'Successful') {
    icon = 'running_with_errors'
    color = 'red'
  } else if (transaction.state.cascading.restore) {
    icon = 'restore'
  } else if (transaction.state.kind === 'fs' || transaction.state.kind === 'fsc' || transaction.state.kind === 'fss') {
    icon = 'savings'
  } else if (transaction.state.cascading.beginning && transaction.state.cascading.end) {
    icon = 'money_off'
    color = 'red'
  } else if (transaction.state.cascading.beginning) {
    icon = 'attach_money'
  } else if (transaction.state.cascading.end) {
    icon = 'price_check'
  } else {
    icon = 'price_check'
  }
  const secondaryText = stateName(transaction.state)

  return (
    <CollapsibleList
      handle={
        <div>
          <ListItem>
            <ListItemGraphic icon={icon} style={{ color: color }} />
            <ListItemText>
              <ListItemPrimaryText style={{ fontWeight: '500' }}>{transaction.currency} {currencyFormat(winlose.toNumber())}</ListItemPrimaryText>
              <ListItemSecondaryText>
                {secondaryText}
              </ListItemSecondaryText>
            </ListItemText>
            <ListItemMeta icon='chevron_right' />
          </ListItem>
          <ListDivider />
        </div>
      }
    >
      <ExpendedContainer darkMode={darkMode} transaction={transaction}>
        <DetailContainer>
          {transaction.state.count !== undefined && <div>Remaining Free Spin: <b>{transaction.state.count}</b></div>}
          {transaction.state.additional > 0 && <div>Additional Free Spin: <b>{transaction.state.additional}</b></div>}
          <div>Date: <b>{date.toLocaleString()}</b></div>
          <div>Bet: <b>{currencyFormat(transaction.bet)}</b></div>
          <div>Win: <b>{currencyFormat(transaction.winAmount)}</b></div>
          <div>End Balance: <b>{currencyFormat(transaction.balance)}</b></div>
          <div>Payout: <b>{transaction.payout}</b></div>
        </DetailContainer>
      </ExpendedContainer>

    </CollapsibleList>
  )
}

export function CandyBurst ({ transaction, darkMode }) {
  if (transaction.state.kind === 'gfs') {
    return <GotFreeSpin transaction={transaction} />
  } else if (transaction.state.cascading.restore) {
    return <MainGameRestored transaction={transaction} />
  }
  return <StandardItem transaction={transaction} />
}
