import React from 'react'
import ReactDOM from 'react-dom'
import { RMWCProvider } from '@rmwc/provider'

import './styles/global.css'
import '@rmwc/theme/styles'
import '@rmwc/icon/styles'
import '@rmwc/circular-progress/styles'
import '@rmwc/list/styles'
import '@rmwc/grid/styles'
import '@rmwc/top-app-bar/styles'
import '@rmwc/button/styles'
import '@rmwc/drawer/styles'
import '@rmwc/card/styles'
import '@rmwc/typography/styles'
import '@rmwc/ripple/styles'
import '@rmwc/chip/styles'
import '@rmwc/snackbar/styles'
import '@rmwc/elevation/styles'

import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <RMWCProvider>
      <App />
    </RMWCProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
