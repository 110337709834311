import { cellsAsMatrix } from './Utils'

export function Win ({ transaction, win, reel, row }) {
  const cells = win.line?.cells || win.cells
  const matrix = cellsAsMatrix(cells, reel, row)

  const trs = []
  for (let i = 0; i < row; i++) {
    const rows = []
    for (let j = 0; j < reel; j++) {
      const cls = matrix[j][i] === 1 ? 'active' : ''
      rows.push(<td key={`${j}-{i}`} className={cls} />)
    }
    trs.push(<tr key={`${i}`}>{rows}</tr>)
  }
  return (
    <table className='payline'>
      <tbody>{trs}</tbody>
    </table>
  )
}
