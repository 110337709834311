import { Snackbar, SnackbarAction } from '@rmwc/snackbar'
import { Card, CardPrimaryAction } from '@rmwc/card'
import { Typography } from '@rmwc/typography'

export default function LoginFailed () {
  return (
    <>
      <Card style={{ margin: '1rem' }}>
        <div style={{ padding: '0 1rem 1rem 1rem' }}>
          <CardPrimaryAction>
            <Typography use='headline6' tag='h2'>
              Victory won't come to me unless I go to it
            </Typography>
            <Typography
              use='subtitle2'
              tag='h3'
              theme='textSecondaryOnBackground'
            >
              by Marianne Moore
            </Typography>
          </CardPrimaryAction>
        </div>
      </Card>
      <Snackbar
        message='Login failed'
        timeout={-1}
        dismissesOnAction
        action={
          <SnackbarAction label='Dismiss' />
        }
        open
      />
    </>
  )
}
