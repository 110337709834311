import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Home from './pages/Home'
import GameTransations from './pages/user/GameTransactions'

export default function App () {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/user/slots/transactions'>
          <GameTransations />
        </Route>
      </Switch>
    </Router>
  )
}
