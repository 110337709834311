import { Card } from '@rmwc/card'
import { currencyFormat } from '../utils/Number'

export const UserBalance = ({ user }) => {
  return (
    <Card style={{ margin: '1rem' }}>
      <div style={{ padding: '1rem' }}>
        {user.balances.map((x, i) => {
          const ccy = x[0]
          const amount = currencyFormat(x[1])
          return (
            <div key={i}>
              {ccy} {amount}
            </div>
          )
        })}
      </div>
    </Card>
  )
}
