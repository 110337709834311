import { useHistory } from 'react-router-dom'
import { Card, CardActions, CardActionButton } from '@rmwc/card'
import { Typography } from '@rmwc/typography'
import { ListDivider } from '@rmwc/list'
import { currencyFormat } from '../utils/Number'
import useDarkMode from 'use-dark-mode'

export const UserProfile = ({ user }) => {
  const router = useHistory()
  const darkMode = useDarkMode()
  const darkStyle = darkMode.value ? { color: 'white' } : undefined

  return (
    <Card style={{ margin: '1rem' }}>
      <div style={{ padding: '1rem' }}>
        <Typography use='headline6' tag='h2'>hello, {user.userName}!</Typography>
        <ListDivider />
        <Typography use='caption' tag='h3'>
          BALANCE
        </Typography>

        {user.balances.map((x, i) => {
          const ccy = x[0]
          const amount = currencyFormat(x[1])
          return (
            <div key={i} style={{ marginTop: '0.5rem' }}>
              {ccy} <Typography use='headline4'>{amount}</Typography>
            </div>
          )
        })}

        <ListDivider style={{ marginTop: '1rem' }} />
        <CardActions fullBleed>
          <CardActionButton
            style={darkStyle}
            label='All game transactions'
            trailingIcon='arrow_forward'
            onClick={() => router.push('/user/slots/transactions')}
          />
        </CardActions>
      </div>
    </Card>
  )
}
